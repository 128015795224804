import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"

import "./blog-post.scss"

import SEO from "../components/seo"

import { Container, ContentBox } from "../components/styledcomponents"
import globals from "../components/globals"
import styled from "styled-components"
import Sumana from "../fonts/Sumana-Regular.woff2"

const ContainerWithSumana = styled(Container)`
  @font-face {
    font-family: "Sumana";
    src: local("Sumana"), url(${Sumana}) format("woff2");
    font-weight: normal;
    font-style: normal;
  }
`

const Post = ({ data, pageContext }) => {
  const post = data.markdownRemark
  const { next, prev } = pageContext
  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.title}
        article
        node={{
          first_publication_date: post.frontmatter.date,
          last_publication_date: post.frontmatter.date,
        }}
      />
      <ContainerWithSumana backgroundColor="white">
        <ContentBox
          maxWidth={globals.media.tablet}
          width="100%"
          textAlign="left"
        >
          {post.frontmatter.featuredImage && (
            <GatsbyImage
              image={
                post.frontmatter.featuredImage.childImageSharp.gatsbyImageData
              }
              alt={
                post.frontmatter.featuredImageAlt
                  ? post.frontmatter.featuredImageAlt
                  : post.frontmatter.title
              }
            />
          )}
          <h1>{post.frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </ContentBox>
      </ContainerWithSumana>
      <div className="blog-nav">
        {next && (
          <Link className="blog-next" to={`/blog/${next.frontmatter.slug}/`}>
            «{next.frontmatter.title}
          </Link>
        )}
        {prev && (
          <Link className="blog-prev" to={`/blog/${prev.frontmatter.slug}/`}>
            {prev.frontmatter.title}»
          </Link>
        )}
      </div>
    </Layout>
  )
}

export default Post

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        excerpt
        featuredImageAlt
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 900, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`
